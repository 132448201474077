import {
  errorBorderColor,
  errorCardBackgroundColor,
  errorColor,
  inputBorderColor,
  radioButtonOptionsLineHeight,
  titleColor,
} from './../../theme'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-70px',
  },
  root: {
    padding: '10px',
    margin: '24px 0 7px 0',
    width: '100%',
    maxWidth: '410px',
    borderRadius: '15px',
    border: 'none',
    textAlign: 'center',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)',
    '& .MuiFormControl-marginNormal': {
      marginTop: '8px',
    },
    '& .MuiFormHelperText-contained': {
      lineHeight: radioButtonOptionsLineHeight,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '20px',
      padding: '0.5px 30px 10px 30px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
  },
  title: {
    fontSize: '28px',
    fontFamily: theme.typography.h2.fontFamily,
    marginBottom: '0',
    marginTop: '28px',
    lineHeight: '34px',
  },
  titleExpired: {
    fontSize: '28px',
    fontFamily: theme.typography.h2.fontFamily,
    marginBottom: '22px',
    marginTop: '28px',
    lineHeight: '34px',
    color: titleColor,
  },
  desc: {
    fontSize: '16px',
    lineHeight: '22px',
    margin: '12px 0 18px 0',
  },
  secondaryDesc: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '30px',
    margin: '20px 0 14px 0',
  },
  disclaimerLinks: {
    minWidth: 'auto',
    background: 'none !important',
    verticalAlign: 'baseline',
    fontSize: '14px',
    padding: '0',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  notchedOutline: {
    borderColor: inputBorderColor,
  },
  validInput: {
    '& .MuiOutlinedInput-notchedOutline, & .MuiSelect-outlined': {
      borderColor: `${theme.palette.secondary.main} !important`,
    },
  },
  errorCard: {
    border: errorBorderColor,
    backgroundColor: errorCardBackgroundColor,
    borderRadius: '8px',
    margin: '24px 0',
    padding: '24px',
    color: errorColor,
  },
  wrapContainer: {
    maxWidth: '400px',
    width: '100%',
    margin: '0 auto',
    paddingBottom: '24px',
    textAlign: 'center',
  },
  wrapContainerForCheck: {
    textAlign: 'center',
    paddingBottom: '24px',
  },
  button: {
    color: '#FFF',
    height: '60px',
    fontWeight: 'bold',
    fontSize: '20px',
    letterSpacing: '2px',
    backgroundColor: theme.palette.primary.main,
  },
  cancelButton: {
    height: '60px',
    fontSize: '20px',
    fontWeight: 'bold',
    background: 'none',
    margin: '20px 0',
    letterSpacing: '2px',
    color: theme.palette.primary.main,
    border: '4px solid transparent',
    borderColor: theme.palette.primary.main,
  },
  idiqIcon: {
    marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  termsLinks: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  secondaryLink: {
    fontWeight: 'bold',
    fontSize: '16px',
    padding: '0',
    verticalAlign: 'baseline',
    borderRadius: '0',
    textTransform: 'none',
    textDecoration: 'underline',
    lineHeight: '22px',
    background: 'none !important',
    color: theme.palette.primary.main,
    border: 0,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  submitButtonWrap: {
    marginTop: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
    },
  },
  separator: {
    fontSize: '16px',
    margin: '0 4px',
  },
  timerText: {
    fontWeight: 'bold',
    margin: '-5px 0 40px 0',
  },
}))
